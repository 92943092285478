.form-section {
    grid-area: var(--grid-form-area);

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.terms-and-cond p {
    text-align: center;
    font-size: var(--small-font-size);
}

.terms-and-cond p a {
    color: var(--main-red-color);
}

.form {
    width: 100%;
    max-width: 800px;
    padding: 10px;

    color: var(--main-text-color);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    flex-wrap: wrap;

    border-radius: 10px;
}

.input-group {
    width: 100%;
    padding: 10px 10px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
}

.input-group label {
    width: 100%;
    padding-bottom: 10px;
}

.input-group .error {
    width: 100%;
    padding: 2px 6px;
    border-radius: 4px;
    transition: all ease-in-out .3s;
    background-color: var(--main-red-color);
    color: var(--main-white-text-color);
}

.input-group .radio-group {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}

.input-group .radio-group input[type="checkbox"],
.input-group .radio-group input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.input-group .radio-group label {
    position: relative;
    margin-right: 1em;
    padding-left: 2em;
    padding-right: 1em;
    line-height: 2;
    cursor: pointer;
    z-index: 2;
}

.input-group .radio-group label::before {
    box-sizing: border-box;
    content: " ";
    position: absolute;
    top: 0.3em;
    left: 0;
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 2px solid var(--main-red-color);
    border-radius: .25em;
    z-index: -1;
}

.input-group .radio-group input[type="radio"]+label::before {
    border-radius: 1em;
}

.input-group .radio-group input[type="checkbox"]:checked+label,
.input-group .radio-group input[type="radio"]:checked+label {
    padding-left: 1em;
    color: var(--main-white-text-color);
}

.input-group .radio-group input[type="checkbox"]:checked+label::before,
.input-group .radio-group input[type="radio"]:checked+label::before {
    top: 0;
    width: 100%;
    height: 2em;
    background-color: var(--main-red-color);
}

.input-group .radio-group label,
.input-group .radio-group label::before {
    transition: .25s all ease;
}

.input-group input,
.input-group select,
.input-group.text-group textarea {
    width: 100%;
    /* max-width: 240px; */
    padding: 10px 20px 8px;

    margin: 0px;
    border: none;
    outline: none;

    border-radius: 8px;

    background-color: rgba(0, 0, 0, 0.04);
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);

    color: var(--main-text-color);

    transition: all ease .3s;
}

.input-group.text-group textarea {
    width: 100%;
    max-width: 580px;
}

.input-group input:hover,
.input-group select:hover,
.input-group.text-group textarea:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid var(--main-red-color);
}

.input-group input:focus,
.input-group select:focus,
.input-group.text-group textarea:focus {
    background-color: rgba(0, 0, 0, 0.08);
    border-bottom: 2px solid var(--main-red-color);
}

.input-group input:disabled,
.input-group select:disabled,
.input-group.text-group textarea:disabled {
    background-color: rgba(0, 0, 0, 0.08);
    border-bottom: 2px solid var(--main-text-color);
}

.input-group input:focus+label {
    font-weight: var(--bold-weight) !important;
}

.form-footer {
    display: flex;
    justify-content: space-evenly;
}

input[type="submit"],
input[type="button"],
button,
a.link-button {
    width: 40%;
    min-width: fit-content;
    padding: 10px 20px 8px;
    text-decoration: none;

    cursor: pointer;

    background-color: rgba(0, 0, 0, 0.2);
    color: var(--main-text-color);

    font-weight: var(--bold-weight);

    margin: 0px;
    border: none;
    outline: none;

    border-radius: 8px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);

    transition: all ease .3s;
}

input[type="submit"] {
    background-color: var(--main-red-color);
    color: var(--main-white-text-color);
}


.full-button {
    width: 100% !important;
}

@media (max-width: 992px) {
    .terms-and-cond p {
        font-size: var(--tanlet-small-font-size);
    }
}

@media (max-width: 768px) {
    .form-section {
        grid-area: var(--device-grid-form-area);
        justify-content: space-evenly;
    }
}



@media (max-width: 640px) {
    .terms-and-cond p {
        font-size: var(--device-small-font-size);
    }
}


@media (prefers-color-scheme: dark) {

    .form {
        background-color: var(--dark-bg-color);
        color: var(--dark-text-color);
    }

    .input-group .radio-group label::before {
        border: 2px solid var(--dark-red-color);
    }

    .input-group .error {
        background-color: var(--dark-red-color);
        color: var(--dark-white-text-color);
    }

    .input-group .radio-group input[type="checkbox"]:checked+label,
    .input-group .radio-group input[type="radio"]:checked+label {
        color: var(--dark-text-color);
    }

    .input-group .radio-group input[type="checkbox"]:checked+label::before,
    .input-group .radio-group input[type="radio"]:checked+label::before {
        background-color: var(--dark-red-color);
    }

    .input-group input,
    .input-group select,
    .input-group.text-group textarea {
        background-color: rgba(255, 255, 255, 0.04);
        color: var(--dark-text-color);
    }

    .input-group input:hover,
    .input-group select:hover,
    .input-group.text-group textarea:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-bottom: 2px solid var(--dark-red-color);
    }

    .input-group input:focus,
    .input-group select:focus,
    .input-group.text-group textarea:focus {
        background-color: rgba(255, 255, 255, 0.08);
        border-bottom: 2px solid var(--dark-red-color);
    }

    input[type="submit"],
    input[type="button"],
    button,
    a.link-button {
        color: var(--dark-text-color);
    }

    .form-footer input[type="submit"],
    button.new-button,
    button.active {
        background-color: var(--dark-red-color);
        color: var(--dark-white-text-color);
    }
}