.badge-component {
    grid-area: var(--grid-badge-area);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.badge {
    width: 35%;
    max-width: 200px;
}

.badge a {
    width: fit-content;
}

.badge a img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .badge-component {
        grid-area: var(--device-grid-badge-area);

        justify-content: space-evenly;

        gap: 10px;
    }

    .badge {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .badge-component {
        flex-direction: column;
    }

    .badge {
        width: 100%;
    }
}