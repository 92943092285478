.legend-section {
    grid-area: var(--grid-legend-area);

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.legend {
    font-size: calc(var(--title-font-size) - 4px);
}

.image {
    width: 100%;
    aspect-ratio: 1.71;
    overflow: hidden;
    background-image: url('assets/legend-discount.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}


@media (max-width: 992px) {
    .legend {
        font-size: calc(var(--tablet-title-font-size) - 4px);
    }
}

@media (max-width: 768px) {
    .legend-section {
        grid-area: var(--device-grid-legend-area);
        justify-content: space-evenly;
    }

    .legend {
        display: none;
    }

    .image {
        width: 60%;
    }
}