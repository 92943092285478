:root {
  /* Normal scheme*/
  --main-bg-color: #e2e2e2;
  --main-bg-content-color: #f2f2f2;
  --main-text-color: #222222;
  --main-white-text-color: #f9f9f9;
  --main-link-color: #2153e9;
  --main-red-color: #db4141;
  --main-grey-color: #4c4c4c;
  --main-light-grey-color: #dcdcdc;
  --main-secondary-light-grey-color: #acacac;
  --main-light-yellow-color: #e8c88a;
  --main-light-red-color: #eb9494;
  --main-light-green-color: #a6f1a6;

  /* Dark colors scheme*/
  --dark-bg-color: #1a1a1a;
  --dark-bg-content-color: #2a2a2a;
  --dark-text-color: #a2a2a2;
  --dark-white-text-color: #c9c9c9;
  --dark-link-color: #809fff;
  --dark-red-color: #be4a4a;
  --dark-grey-color: #3c3c3c;
  --dark-light-grey-color: #5c5c5c;
  --dark-secondary-light-grey-color: #4c4c4c;
  --dark-yellow-color: #9b7c00;
  --dark-secondary-red-color: #6e1515;
  --dark-green-color: #006600;

  /*Fonts*/
  --code-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --main-font: 'Open Sans', sans-serif;

  /*Font weights*/
  --regular-weight: 400;
  --semibold-weight: 500;
  --bold-weight: 700;

  --font-size: 16px;
  --small-font-size: 14px;
  --title-font-size: 24px;

  --tablet-font-size: 14px;
  --tablet-small-font-size: 12px;
  --tablet-title-font-size: 20px;

  --device-font-size: 12px;
  --device-small-font-size: 10px;
  --device-title-font-size: 18px;

  /*GRID Layout*/
  --grid-welcome-area: 1 / 1 / 4 / 6;
  --grid-form-area: 4 / 1 / 12 / 6;
  --grid-legend-area: 1 / 7 / 12 / 13;

  --grid-message-area: 1 / 1 / 7 / 13;
  --grid-badge-area: 7 / 1 / 13 / 13;

  --device-grid-welcome-area: 1 / 1 / 3 / 13;
  --device-grid-legend-area: 3 / 1 / 7 / 13;
  --device-grid-form-area: 7 / 1 / 13 / 13;

  --device-grid-message-area: 1 / 1 / 6 / 13;
  --device-grid-badge-area: 7 / 1 / 13 / 13;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-family: var(--main-font);
  font-size: var(--font-size);

  transition: font-size ease .3s;
}

.app {
  width: 100%;
  height: 100vh;
  overflow: none;
  background-image: url('./assets/blurred-leafs-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: auto;
}

.logo {
  width: 90%;
  max-width: 400px;
  aspect-ratio: 16 / 4;
}

.logo img {
  width: 100%;
}

.referrer-modal,
.success-modal,
.bad-modal {
  width: 90%;
  max-width: 940px;
  height: fit-content;
  max-height: 800px;

  border-radius: 12px;

  padding: 30px 38px;

  background-color: var(--main-bg-content-color);

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.paragraph {
  text-align: center;
  line-height: 1.2;
}

.details {
  grid-area: var(--grid-message-area);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: center;
  align-items: center;

  gap: 6px;
}

.details .title,
.details .paragraph {
  width: fit-content;
}

.details .title{ text-align: center;}
@media (max-width: 992px) {
  body {
    font-size: var(--tablet-font-size);
  }
}

@media (max-width: 768px) {
  .logo {
    max-width: 300px;
  }

  .referrer-modal,
  .success-modal,
  .bad-modal {
    width: 80%;
  }

  .details {
    grid-area: var(--device-grid-message-area);
  }
}

@media (max-width: 640px) {
  body {
    font-size: var(--device-font-size);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);
  }

  .home {
    background: rgba(0, 0, 0, 0.4);
  }

  .referrer-modal,
  .success-modal,
  .bad-modal {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);
  }
}