.welcome-section {
    grid-area: var(--grid-welcome-area);

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 14px;
    align-items: center;
}

.title {
    font-size: var(--title-font-size);
    font-weight: 700;
}


@media (max-width: 992px) {
    .title {
        font-size: var(--tablet-title-font-size);
    }
}

@media (max-width: 768px) {
    .welcome-section {
        grid-area: var(--device-grid-welcome-area);
        justify-content: space-evenly;
        gap: 0px;
    }
}

@media (max-width: 640px) {
    .title {
        font-size: var(--device-title-font-size);
    }
}